import React, { useState } from "react";
import leftPic from "../assets/images/accounts/loginPic.png";
import Input from "../shared/input";
import Buttoon from "../components/buttoon";
import { Link, useNavigate } from "react-router-dom";
import google from "../assets/icons/google.svg";
import Swal from "sweetalert2";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // New state to track loading

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent additional clicks
    if (validate()) {
      setLoading(true); // Set loading state to true
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/login`,
          { email, password }
        );
        const { token, name } = response.data;

        localStorage.setItem("name", name);
        localStorage.setItem("token", token);

        const newChatResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
          {}
        );

        const chatId = newChatResponse.data.chat_id;

        localStorage.setItem("chat_id", chatId);

        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "You have successfully logged in!",
          customClass: {
            icon: "custom-icon",
            title: "custom-title",
            content: "custom-content",
            confirmButton: "custom-button",
          },
        });

        navigate(`/dashboard/${chatId}`);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: error.response?.data.message || "Invalid credentials",
        });
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };

  return (
    <>
      <div className="loginWrapper">
        <div className="mainContainer">
          <div className="leftArea">
            <img src={leftPic} alt="Login Illustration" />
          </div>
          <div className="rightArea v-center h-center">
            <div className="midBox">
              <div className="loginTitle v-center h-center flex-column gap-2">
                <h3 className="fw-bold">Log In</h3>
                <p className="fw-semibold">
                  Enter your email address and password to login.
                </p>
              </div>
              <form className="w-100" onSubmit={handleSubmit}>
                <div className="inputFieldContainer v-center h-center flex-column gap-3">
                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="email">
                      Email
                    </label>
                    <Input
                      wrapperClass="w-100"
                      inputClass="fw-medium text-black"
                      value={email}
                      height="46px"
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <p className="error-message">{errors.email}</p>
                    )}
                  </div>

                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="password">
                      Password
                    </label>
                    <Input
                      wrapperClass="w-100"
                      inputClass="fw-medium text-black pe-5"
                      value={password}
                      height="46px"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      icon={showPassword ? "eye-off" : "eye"}
                      onIconClick={togglePasswordVisibility}
                    />
                    {errors.password && (
                      <p className="error-message">{errors.password}</p>
                    )}
                  </div>
                  <div className="rememberMeDiv d-flex justify-content-between w-100">
                    <div className="leftSide">
                      <label className="d-flex gap-2">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                        />
                        <span className="fw-normal text-small cursor-pointer">
                          Remember me!
                        </span>
                      </label>
                    </div>
                    <div className="rightSide">
                      <Link
                        className="text-black fw-normal text-small text-decoration-underline"
                        to="/forget-password"
                      >
                        Forget your password?
                      </Link>
                    </div>
                  </div>
                </div>
                <Buttoon
                  className="btn btn-primary mb-1 mt-4 nextBtn"
                  width={100}
                  minWidth={360}
                  minHeight={39}
                  text={loading ? "Logging in..." : "Login"} // Button text updates when loading
                  type="submit"
                  disabled={loading} // Disable button during loading
                />
              </form>
              <div className="bottomSection v-center h-center flex-column gap-3 ">
                <div className="orLoginText v-center h-center w-100 gap-3">
                  <div className="grayLine"></div>
                  <p className="text-small fw-semibold">or login with</p>
                  <div className="grayLine"></div>
                </div>
                <div className="socialIconss v-center h-center w-100 gap-4 mt-2">
                  <img
                    className="cursor-pointer"
                    src={google}
                    alt="Google Login"
                  />
                </div>
                <p className="text-small fw-normal mt-4">
                  Don’t have an account?{" "}
                  <Link to="/signup">
                    <span className="text-primary text-underline">
                      Create account
                    </span>
                  </Link>
                  <span>&nbsp;here</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
