import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import threedots from "../assets/icons/3dots.svg";
import DelCardModal from "../dashboard/modals/delCardModal";
import RenameChatModal from "../dashboard/modals/RenameChatModal";

const ThreeDots = ({ chatId, documentId, handleDeleteChat, handleDeleteDocument, handleRenameChat, handleRenameDocument }) => {
  const dropRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [actionType, setActionType] = useState("chat");

  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handletoggle = () => {
    setShowPopup(!showPopup);
  };

  const showDelModal = () => {
    setIsDelModalOpen(true);
  };

  const showRenameModal = () => {
    // Determine the action type (chat or document) based on what's available
    if (documentId) {
      setActionType("document");
    } else {
      setActionType("chat");
    }
    setIsRenameModalOpen(true);
    setIsDelModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (handleDeleteChat) {
      handleDeleteChat(chatId);
    } else {
      handleDeleteDocument(chatId);
    }
    setIsDelModalOpen(false);
  };

  return (
    <>
      <div className="rightArea" ref={dropRef}>
        <img
          src={threedots}
          alt="three dots"
          className="cursor-pointer"
          onClick={handletoggle}
        />
        {showPopup && (
          <div className="editDiv position-absolute">
            <div className="cursor-pointer v-center justify-content-left gap-2 mb-3" onClick={showRenameModal}>
              <Icon
                icon="la:pen"
                width="20"
                height="20"
                style={{ color: "black" }}
              />
              <span className="cursor-pointer text-small fw-medium">
                Rename
              </span>
            </div>
            <div
              className="cursor-pointer v-center justify-content-left gap-2"
              onClick={showDelModal}
            >
              <Icon
                icon="ph:trash"
                width="19"
                height="19"
                style={{ color: "red" }}
              />
              <span className="cursor-pointer text-danger text-small fw-medium">
                Delete
              </span>
            </div>
          </div>
        )}
      </div>

      <DelCardModal
        isModalOpen={isDelModalOpen}
        setIsModalOpen={setIsDelModalOpen}
        onConfirm={handleConfirmDelete}
      />
      
      <RenameChatModal
        isModalOpen={isRenameModalOpen}
        setIsModalOpen={setIsRenameModalOpen}
        chatId={chatId}
        documentId={documentId}
        actionType={actionType}  // Pass actionType to determine if it's chat or document
        handleRenameChat={handleRenameChat} 
        handleRenameDocument={handleRenameDocument}
      />
    </>
  );
};

export default ThreeDots;
