import React, { useState, useEffect } from "react";
import { Modal, Input, Button } from "antd";

const RenameChatModal = ({ isModalOpen, setIsModalOpen, chatId, documentId, actionType, handleRenameChat, handleRenameDocument }) => {
  const [newName, setNewName] = useState("");

  useEffect(() => {
    setNewName("");  // Reset the name input field when modal opens
  }, [isModalOpen]);

  const handleOk = async () => {
    if (newName.trim() === "") return;  // Don't proceed if input is empty

    // Rename chat or document based on actionType
    if (actionType === "chat") {
      await handleRenameChat(chatId, newName);  // Call handleRenameChat for chat
    } else if (actionType === "document") {
      await handleRenameDocument(documentId, newName);
    }

    setIsModalOpen(false);  // Close the modal
    setNewName("");  // Reset name state
  };

  const handleCancel = () => {
    setIsModalOpen(false);  // Close modal
    setNewName("");  // Reset name state
  };

  return (
    <Modal
      title={actionType === "chat" ? "Rename Chat" : "Rename Document"}  // Dynamic title based on actionType
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}  // No footer buttons
    >
      <Input
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        placeholder={actionType === "chat" ? "Enter new chat name" : "Enter new document name"}  // Dynamic placeholder based on actionType
      />
      {newName.trim() !== "" && (  // Only show the button if the input is not empty
        <Button type="primary" className="mt-2" onClick={handleOk}>
          Update {actionType === "chat" ? "Chat Name" : "Document Name"}
        </Button>
      )}
    </Modal>
  );
};

export default RenameChatModal;
