import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer, Modal } from "antd";
import moment from "moment";
import finalLogo from "../assets/icons/kycLogo.svg";
import Buttoon from "./buttoon";
import btnIcon from "../assets/icons/btnIcon.svg";
import ThreeDots from "../shared/threeDots";
import PricingUpgrade from "./pricing/pricingUpgarde";
import axios from "axios";

const Sidebar = ({ isDrawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [userDocument, setUserDocument] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentNewChatId, setCurrentNewChatId] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1025);
    };
    window.addEventListener("resize", checkIfMobile);
    checkIfMobile();

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const fetchUserChats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chatApi/chat`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching user chats:", error);
    }
  };

  const fetchUserDocument = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chatApi/savedDocument`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserDocument(response.data.data); // Update the document state with fetched data
    } catch (error) {
      console.error("Error fetching user document:", error);
    } finally {
    }
  };
  
  useEffect(() => {
    fetchUserDocument();
  
    const intervalId = setInterval(fetchUserDocument, 5000); // Poll every 5 seconds
  
    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchUserChats();
    const startPolling = () => {
      fetchUserChats();

      const interval = setInterval(fetchUserChats,
         5000);
      setPollingInterval(interval);
    };

    startPolling();

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
        setPollingInterval(null);
      }
    };
  }, [currentNewChatId]);

  const handleNewChatClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
        {}
      );

      const newChatId = response.data.chat_id;
      setCurrentNewChatId(newChatId);

      const newUserData = [
        { chat_id: newChatId, name: "New Chat", isNew: true },
        ...userData,
      ];

      setUserData(newUserData);
      navigate(`/dashboard/${newChatId}`);
    } catch (error) {
      console.error("Failed to create a new chat:", error);
    }
  };

  const handleRenameChat = async (chatId, newChatName) => {
    console.log(chatId, newChatName);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/chatApi/change_chatName`,
        { chat_id: chatId, name: newChatName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedChat = response.data.updatedChat;
      console.log(response.data.updatedChat);

      const updatedUserData = userData.map((chat) =>
        chat.chat_id === chatId ? { ...chat, name: updatedChat.name } : chat
      );

      setUserData(updatedUserData);
    } catch (error) {
      console.error("Failed to rename chat:", error);
    }
  };


  const handleRenameDocument = async (documentId, newChatName) => {
    console.log(documentId, newChatName);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/change_documentName`,
        { chat_id: documentId, name: newChatName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedChat = response.data.updatedChat;
      console.log(response.data.updatedChat);

      const updatedUserData = userData.map((chat) =>
        chat.chat_id === documentId ? { ...chat, name: updatedChat.name } : chat
      );

      setUserData(updatedUserData);
    } catch (error) {
      console.error("Failed to rename chat:", error);
    }
  };

  const today = moment().startOf("day");
  const todayChats = userData.filter((chat) =>
    moment(chat.created_at).isSame(today, "day")
  );
  const yesterdayChats = userData.filter((chat) =>
    moment(chat.created_at).isBefore(today, "day")
  );
  const todayDocument = userDocument.filter((chat) =>
    moment(chat.created_at).isSame(today, "day")
  );
  const yesterdayDocument = userDocument.filter((chat) =>
    moment(chat.created_at).isBefore(today, "day")
  );

  const handleDeleteChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/chatApi/deletechat`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { chat_id: chatId },
        }
      );

      const updatedUserData = userData.filter(
        (chat) => chat.chat_id !== chatId
      );

      setUserData(updatedUserData);
      localStorage.setItem("chat_list", JSON.stringify(updatedUserData));

      if (!updatedUserData.some((chat) => chat.name === "New Chat")) {
        handleNewChatClick();
      }
    } catch (error) {
      console.error("Failed to delete chat:", error);
    }
  };
  
  const handleDeleteDocument = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
  
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/chatApi/delete_savedDocument`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { chat_id: chatId }, // Ensure correct identifier
        }
      );
  
      const updatedUserDocument = userDocument.filter(
        (document) => document.chat_id !== chatId
      );
  
      setUserDocument(updatedUserDocument);
  
      localStorage.setItem(
        "document_list",
        JSON.stringify(updatedUserDocument)
      );
  
      console.log("Document deleted successfully:", chatId);
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Desktop Sidebar */}
      {!isMobile && (
        <div className="sidebar-main-container d-flex flex-column">
          <div className="scrollable">
            <div className="sidebar-logo-container d-flex justify-content-start w-100">
            <Link to="/">
                    <img src={finalLogo} alt="logo" className="logo" />
                  </Link>
            </div>

            <Buttoon
              className="btn btn-primary w-100 newChatIcon d-flex v-center"
              minWidth={208}
              minHeight={39}
              img={btnIcon}
              text="New Chat"
              imgClass="newChatIcon"
              onClick={handleNewChatClick}
            />

            <div
              style={{ width: "100%" }}
              onClick={showModal}
              className="d-flex flex-column gap-1 pricePopup mt-3 cursor-pointer"
            >
              <p style={{ fontSize: "12px" }}>
                You have used 3 of 3 free chats.
              </p>
              <div className="coloredDiv w-100"></div>
              <p style={{ fontSize: "12px" }} className="text-primary">
                Upgrade for less than $7 / month
              </p>
            </div>

            <div className="sidebar-options-container d-flex flex-column space-between">
              <div>
                <div className="myChatBox">
                  <p className="text-small fw-bold text-left">My Chats</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  {todayChats.map((value, index) => (
                    <div className="mainList d-flex justify-content-between v-center">
                      <div className="leftArea d-flex gap-2 ">
                        <NavLink
                          to={`/dashboard/${value.chat_id}`}
                          className="text-black d-flex gap-2"
                        >
                          <Icon
                            icon="circum:chat-2"
                            width="20"
                            height="20"
                            style={{ color: "gray" }}
                            className="sideIconss"
                          />

                          <span className="text-small fw-medium">
                            {value.name}
                          </span>
                        </NavLink>
                      </div>
                      <ThreeDots
                        chatId={value.chat_id}
                        handleDeleteChat={handleDeleteChat}
                        handleRenameChat={handleRenameChat}
                      />
                    </div>
                  ))}
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Yesterday's
                  </p>
                  {yesterdayChats.map((value, index) => (
                    <div className="mainList d-flex justify-content-between v-center">
                      <div className="leftArea d-flex gap-2 ">
                        <NavLink
                          to={`/dashboard/${value.chat_id}`}
                          className="text-black d-flex gap-2"
                        >
                          <Icon
                            icon="circum:chat-2"
                            width="20"
                            height="20"
                            style={{ color: "gray" }}
                            className="sideIconss"
                          />

                          <span className="text-small fw-medium">
                            {value.name}
                          </span>
                        </NavLink>
                      </div>
                      <ThreeDots
                        chatId={value.chat_id}
                        handleDeleteChat={handleDeleteChat}
                        handleRenameChat={handleRenameChat}
                      />
                    </div>
                  ))}
                </div>

                <div className="myChatBox">
                  <p className="text-small fw-bold text-left">My Files</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  {todayDocument
                    .filter((value) => value.saved_document_url !== undefined)
                    .map((value, index) => (
                      <div
                        key={index}
                        className="mainList d-flex justify-content-between v-center"
                      >
                        <NavLink
                          to={`/dashboard/file/${value.chat_id}`}
                          state={{
                            savedDocument: value.saved_document_url,
                            chatId: value.chat_id,
                            chatName: value.name,
                          }}
                          className="text-black"
                        >
                          <div className="leftArea d-flex gap-2">
                            <Icon
                              icon="mdi:paper-edit-outline"
                              width="20"
                              height="20"
                              style={{ color: "gray" }}
                              className="sideIconss"
                            />
                            <span className="text-small fw-medium">
                              {value.name}
                            </span>
                          </div>
                        </NavLink>
                        <ThreeDots
                          chatId={value.chat_id}
                          documentId={value.chat_id}
                          handleDeleteDocument={handleDeleteDocument}
                          handleRenameDocument={handleRenameDocument}

                        />
                      </div>
                    ))}

                  <p className="todayClr text-left text-small fw-normal my-2">
                    Yesterday's
                  </p>
                  {yesterdayDocument
                    .filter((value) => value.saved_document_url !== undefined)
                    .map((value, index) => (
                      <div
                        key={index}
                        className="mainList d-flex justify-content-between v-center"
                      >
                        <NavLink
                          to={`/dashboard/file/${value.chat_id}`}
                          state={{
                            savedDocument: value.saved_document_url,
                            chatId: value.chat_id,
                            chatName: value.name,
                          }}
                          className="text-black"
                        >
                          <div className="leftArea d-flex gap-2">
                            <Icon
                              icon="mdi:paper-edit-outline"
                              width="20"
                              height="20"
                              style={{ color: "gray" }}
                              className="sideIconss"
                            />
                            <span className="text-small fw-medium">
                              {value.name}
                            </span>
                          </div>
                        </NavLink>
                        <ThreeDots
                          chatId={value.chat_id}
                          documentId={value.chat_id}
                          handleDeleteDocument={handleDeleteDocument}
                          handleRenameDocument={handleRenameDocument}
                        />{" "}
                      </div>
                    ))}
                </div>
              </div>

              <NavLink
                className="accLinkColr text-black  "
                to="/settings/profile"
                onClick={toggleDrawer}
                style={{ width: "fit-content" }}
              >
                <div style={{ width: "auto" }} className="accSetting py-3 ">
                  <div
                    className="v-center gap-2 bottom-link"
                    style={{ width: "fit-content" }}
                  >
                    <Icon
                      icon="ion:settings-outline"
                      width="24"
                      height="24"
                      style={{ color: "#0085FF" }}
                    />
                    <span className="accText fw-bold text-small">
                      Account Settings
                    </span>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Sidebar */}
      {isMobile && (
        <div className="mobile-sidebar">
          <Drawer
            placement="left"
            closable={false}
            onClose={toggleDrawer}
            open={isDrawerOpen}
            width={300}
            className="mobile-menu-drawer"
          >
            <div
              className="sidebar-container d-flex flex-column"
              style={{ overflowY: "auto", height: "100%" }}
            >
              <div className="scrollable">
                <div className="sidebar-logo-container mb-2">
                  <Link to="/">
                    <img src={finalLogo} alt="logo" className="logo" />
                  </Link>
                </div>

                <Buttoon
                  className="btn btn-primary w-100 newChatIcon d-flex v-center"
                  minWidth={208}
                  minHeight={39}
                  img={btnIcon}
                  text="New Chat"
                  imgClass="newChatIcon"
                  // onClick={handleNewChatClick}
                  onClick={() => (handleNewChatClick(), toggleDrawer())}
                />

                <div
                  onClick={() => (showModal(), toggleDrawer())}
                  className="d-flex flex-column gap-1 pricePopup my-3 cursor-pointer"
                >
                  <p style={{ fontSize: "12px" }}>
                    You have used 3 of 3 free chats.
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "8px",
                      backgroundImage:
                        "linear-gradient(92deg, #009EFF 0.8%, #9360FF 98.13%)",
                      borderRadius: "5px",
                    }}
                  />
                  <p style={{ fontSize: "12px" }} className="text-primary">
                    Upgrade for less than $7 / month
                  </p>
                </div>

                <div className="sidebar-options-container d-flex flex-column space-between">
                  <div>
                    <div className="myChatBox">
                      <p className="text-small fw-bold text-left">My Chats</p>
                      <hr />
                      <p className="todayClr text-left text-small fw-normal my-2">
                        Today's
                      </p>
                      {todayChats.map((value, index) => (
                        <div
                          onClick={() => toggleDrawer()}
                          className="mainList d-flex justify-content-between v-center"
                        >
                          <div className="leftArea d-flex gap-2 ">
                            <NavLink
                              to={`/dashboard/${value.chat_id}`}
                              className="text-black d-flex gap-2"
                            >
                              <Icon
                                icon="circum:chat-2"
                                width="20"
                                height="20"
                                style={{ color: "gray" }}
                                className="sideIconss"
                              />

                              <span className="text-small fw-medium">
                                {value.name}
                              </span>
                            </NavLink>
                          </div>
                          <ThreeDots
                            chatId={value.chat_id}
                            handleDeleteChat={handleDeleteChat}
                            handleRenameChat={handleRenameChat}
                          />
                        </div>
                      ))}

                      <p className="todayClr text-left text-small fw-normal my-2">
                        Yesterday's
                      </p>
                      {yesterdayChats.map((value, index) => (
                        <div
                          onClick={() => toggleDrawer()}
                          className="mainList d-flex justify-content-between v-center"
                        >
                          <div className="leftArea d-flex gap-2 ">
                            <NavLink
                              to={`/dashboard/${value.chat_id}`}
                              className="text-black d-flex gap-2"
                            >
                              <Icon
                                icon="circum:chat-2"
                                width="20"
                                height="20"
                                style={{ color: "gray" }}
                                className="sideIconss"
                              />

                              <span className="text-small fw-medium">
                                {value.name}
                              </span>
                            </NavLink>
                          </div>
                          <ThreeDots
                            chatId={value.chat_id}
                            handleDeleteChat={handleDeleteChat}
                            handleRenameChat={handleRenameChat}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="myChatBox">
                      <p className="text-small fw-bold text-left">My Files</p>
                      <hr />
                      <p className="todayClr text-left text-small fw-normal my-2">
                        Today's
                      </p>
                      {todayDocument
                        .filter(
                          (value) => value.saved_document_url !== undefined
                        )
                        .map((value, index) => (
                          <div
                            onClick={() => toggleDrawer()}
                            key={index}
                            className="mainList d-flex justify-content-between v-center"
                          >
                            <NavLink
                              to={`/dashboard/file/${value.chat_id}`}
                              state={{
                                savedDocument: value.saved_document_url,
                                chatId: value.chat_id,
                                chatName: value.name,
                              }}
                              className="text-black"
                            >
                              <div className="leftArea d-flex gap-2">
                                <Icon
                                  icon="mdi:paper-edit-outline"
                                  width="20"
                                  height="20"
                                  style={{ color: "gray" }}
                                  className="sideIconss"
                                />
                                <span className="text-small fw-medium">
                                  {value.name}
                                </span>
                              </div>
                            </NavLink>{" "}
                            {/* Closing NavLink tag */}
                            <ThreeDots
                              chatId={value.chat_id}
                              handleDeleteChat={handleDeleteChat}
                            />
                          </div>
                        ))}

                      <p className="todayClr text-left text-small fw-normal my-2">
                        Yesterday's
                      </p>
                      {yesterdayChats.map((value, index) => (
                        <div
                          onClick={toggleDrawer}
                          className="mainList d-flex justify-content-between v-center"
                        >
                          <div className="leftArea d-flex gap-2 ">
                            <NavLink
                              to={`/dashboard/${value.chat_id}`}
                              className="text-black d-flex gap-2"
                            >
                              <Icon
                                icon="circum:chat-2"
                                width="20"
                                height="20"
                                style={{ color: "gray" }}
                                className="sideIconss"
                              />

                              <span className="text-small fw-medium">
                                {value.name}
                              </span>
                            </NavLink>
                          </div>
                          <ThreeDots
                            chatId={value.chat_id}
                            handleDeleteChat={handleDeleteChat}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="myChatBox">
                      <p className="text-small fw-bold text-left">My Files</p>
                      <hr />
                      <p className="todayClr text-left text-small fw-normal my-2">
                        Today's
                      </p>
                      {todayDocument
                        .filter(
                          (value) => value.saved_document_url !== undefined
                        )
                        .map((value, index) => (
                          <div
                            onClick={toggleDrawer}
                            key={index}
                            className="mainList d-flex justify-content-between v-center"
                          >
                            <NavLink
                              to={`/dashboard/file/${value.chat_id}`}
                              state={{
                                savedDocument: value.saved_document_url,
                                chatId: value.chat_id,
                                chatName: value.name,
                              }}
                              className="text-black"
                            >
                              <div className="leftArea d-flex gap-2">
                                <Icon
                                  icon="mdi:paper-edit-outline"
                                  width="20"
                                  height="20"
                                  style={{ color: "gray" }}
                                  className="sideIconss"
                                />
                                <span className="text-small fw-medium">
                                  {value.name}
                                </span>
                              </div>
                            </NavLink>
                            <ThreeDots
                              chatId={value.chat_id}
                              handleDeleteDocument={handleDeleteDocument}
                            />
                          </div>
                        ))}

                      <p className="todayClr text-left text-small fw-normal my-2">
                        Yesterday's
                      </p>
                      {yesterdayDocument
                        .filter(
                          (value) => value.saved_document_url !== undefined
                        )
                        .map((value, index) => (
                          <div
                            onClick={toggleDrawer}
                            key={index}
                            className="mainList d-flex justify-content-between v-center"
                          >
                            <NavLink
                              to={`/dashboard/file/${value.chat_id}`}
                              state={{
                                savedDocument: value.saved_document_url,
                                chatId: value.chat_id,
                                chatName: value.name,
                              }}
                              className="text-black"
                            >
                              <div className="leftArea d-flex gap-2">
                                <Icon
                                  icon="mdi:paper-edit-outline"
                                  width="20"
                                  height="20"
                                  style={{ color: "gray" }}
                                  className="sideIconss"
                                />
                                <span className="text-small fw-medium">
                                  {value.name}
                                </span>
                              </div>
                            </NavLink>
                            <ThreeDots
                              chatId={value.chat_id}
                              handleDeleteDocument={handleDeleteDocument}
                            />{" "}
                          </div>
                        ))}
                    </div>
                  </div>

                  <NavLink
                    className="accLinkColr text-black"
                    to="/settings/profile"
                    onClick={toggleDrawer}
                  >
                    <div className="accSetting py-3">
                      <div
                        className="v-center gap-2 bottom-link bg-red"
                        style={{
                          width: "fit-content",
                        }}
                      >
                        <Icon
                          icon="ion:settings-outline"
                          width="24"
                          height="24"
                          style={{ color: "#0085FF" }}
                        />
                        <span className="accText fw-bold text-small">
                          Account Settings
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      )}

      <Modal
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={837}
        // className="upgrade-plans"
      >
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Sidebar;
