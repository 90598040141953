import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Input from "../../../shared/input";
import Buttoon from "../../../components/buttoon";
import { Modal } from "antd";
import PricingUpgrade from "../../../../src/components/pricing/pricingUpgarde";

const Profile = ({ setActive }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [User, setUser] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setActive(value);
  };

  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_website: "",
    department: "",
    about: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
  
    const textOnlyPattern = /^[A-Za-z\s]+$/; 
    const textAndNumberPattern = /[A-Za-z]/; 
  
    // Check required fields
    if (!formFields.first_name.trim()) {
      errors.first_name = "First Name is required";
    } else if (!textOnlyPattern.test(formFields.first_name)) {
      errors.first_name = "First Name should contain only letters";
    }
  
    if (!formFields.last_name.trim()) {
      errors.last_name = "Last Name is required";
    } else if (!textOnlyPattern.test(formFields.last_name)) {
      errors.last_name = "Last Name should contain only letters";
    }
  
    if (!formFields.department.trim()) {
      errors.department = "Department is required";
    } else if (!textOnlyPattern.test(formFields.department)) {
      errors.department = "Department should contain only letters";
    }
  
    if (!formFields.about.trim()) {
      errors.about = "Description is required";
    } else if (!textAndNumberPattern.test(formFields.about)) {
      errors.about = "Description should include letters and may include numbers";
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    const fetchLoginUser = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/fetch_login_user`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUser(response.data.data);
      } catch (error) {
        console.error("Error fetching login profile:", error);
      }
    };
    fetchLoginUser();
  }, [setUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const token = localStorage.getItem("token");
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/profile_update`,
          formFields,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        Swal.fire({
          title: "Success",
          text: "Your profile has been updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFormFields({
          first_name: "",
          last_name: "",
          department: "",
          about: "",
        });
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Error updating profile",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error updating profile:", error);
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fix the errors in the form",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="profileContent">
        <div className="billingDiv d-flex flex-column justify-content-left gap-2">
          <p className="fw-semibold">Billing</p>
          <p className="fw-medium">
            You are on a trial on ChatKYC &nbsp;
            <span onClick={() => setIsModalOpen(true)} className="text-primary cursor-pointer">
              Upgrade Now.
            </span>
          </p>
        </div>

        <div className="billingDiv d-flex flex-column justify-content-left gap-2">
          <p className="fw-semibold">Profile</p>
          <p className="fw-medium">
            Setting up your profile will personalize your experience with ChatKYC.
          </p>
        </div>

        <form className="w-100" onSubmit={handleSubmit}>
          <div className="nameDiv d-flex  w-100">
            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="first_name">
                First Name <span className="text-red"> *</span>
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.first_name}
                height="46px"
                type="text"
                name="first_name"
                onChange={handleInputChange}
              />
              {errors.first_name && (
                <p className="error-message" style={{ color: "red" }}>
                  {errors.first_name}
                </p>
              )}
            </div>

            <div className="fnameContainer d-flex flex-column last-name">
              <label className="fw-bold mb-2" htmlFor="last_name">
                Last Name <span className="text-red"> *</span>
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.last_name}
                height="46px"
                type="text"
                name="last_name"
                onChange={handleInputChange}
              />
              {errors.last_name && (
                <p className="error-message" style={{ color: "red" }}>
                  {errors.last_name}
                </p>
              )}
            </div>
          </div>

          <div className="nameDiv d-flex justify-content-between w-100 mt-4">
            <div className="fnameContainer d-flex flex-column role-input">
              <label className="fw-bold mb-2" htmlFor="department">
                Role <span className="text-red"> *</span>
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.department}
                height="46px"
                type="text"
                name="department"
                onChange={handleInputChange}
              />
              {errors.department && (
                <p className="error-message" style={{ color: "red" }}>
                  {errors.department}
                </p>
              )}
            </div>
          </div>

          <div className="fnameContainer d-flex flex-column mt-4 about-div ">
            <label className="fw-bold mb-2" htmlFor="about">
              About You <span className="text-red"> *</span>
            </label>
            <Input
              wrapperClass="rounded"
              inputClass="fw-medium text-black"
              value={formFields.about}
              minHeight={93}
              type="text"
              name="about"
              onChange={handleInputChange}
            />
            {errors.about && <p className="error-message" style={{ color: "red" }}>{errors.about}</p>}
          </div>

          <div className="d-flex justify-center save-profile">
            <Buttoon
              className="btn btn-primary m-auto mt-4"
              minWidth={216}
              minHeight={43}
              text="Save Profile"
            />
          </div>
        </form>
      </div>

      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={837}
        // className="upgrade-plans"
      >
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Profile;
