import React, { useState } from "react";
import { Button, Modal } from "antd";
import Input from "../../shared/input";
import Buttoon from "../../components/buttoon";
import axios from "axios";
import Swal from "sweetalert2";

const ChangeName = ({ isnamemodal, setisnamemodal, fetchLoginUser }) => {
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false); // Error state for name validation

  const handleCancel = () => {
    setisnamemodal(false);
    setNameError(false); // Reset error on cancel
    setNewName(""); // Reset input on cancel
  };

  const handleChangeName = async () => {
    // Check if the name is empty
    if (!newName.trim()) {
      setNameError(true); // Set error if name is empty
      return;
    }
    setNameError(false); // Reset error if validation passes
    setLoading(true);

    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update_name`,
        { name: newName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Name updated successfully", response.data);
      fetchLoginUser();
      setLoading(false);
      handleCancel();
      Swal.fire({
        title: "Success",
        text: "Your name has been updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating name:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "There was an error updating your name. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal
      open={isnamemodal}
      className="credit-modal"
      centered={true}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modal-content">
        <div className="pass-modal-header w-100">
          <h4 className="fw-bold">Change Name</h4>
        </div>
        <div className="pass-modal-content">
          <div className="c-pass-div h-center justify-content-start flex-column gap-2">
            <label className="fw-bold" htmlFor="">
              Enter Your Name
            </label>
            <Input
              height="46px"
              type="text"
              className={`curnt-pass w-100 ${nameError ? "error-border" : ""}`} // Add error class if name is empty
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            {nameError && (
              <span className="error-text" style={{ color: "red" }}>
                Name is empty
              </span>
            )}
          </div>

          <div className="h-center">
          <Buttoon
  className="btn btn-primary mt-5 m-auto"
  minWidth={166}
  minHeight={43}
  text={loading ? "Saving" : "Save"} // Dynamically update button text
  onClick={handleChangeName}
  loading={loading}
/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeName;
